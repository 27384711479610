/**********Sticky header ************ */
// document.addEventListener("DOMContentLoaded", onDOMReady);
// function onDOMReady() {
//   const body = document.querySelector("body");
//   const menuHeader = document.querySelector("header");
//   const mainContainer = document.querySelector("main");
//   if (Boolean(menuHeader, mainContainer)) {
//     window.addEventListener("scroll", onWindowScroll);
//     function onWindowScroll() {
//       if (window.document.scrollingElement.scrollTop > 120) {
//         body.classList.add("sticky");
//         menuHeader.classList.add("sticky");
//         mainContainer.classList.add("main__margin-top");
//       } else {
//         body.classList.remove("sticky");
//         menuHeader.classList.remove("sticky");
//         mainContainer.classList.remove("main__margin-top");
//       }
//     }
//   }
// }

/* **** Burger **** */
const burgerHandler = () => {
  if (!document.querySelector(".burger-menu")) {
    return;
  }
  const burgerBtn = document.querySelector(".burger-menu");
  const menu = document.querySelector(".main-menu");
  const body = document.querySelector("body");
  burgerBtn.addEventListener("click", handleMenu);
  function handleMenu() {
    burgerBtn.classList.toggle("show");
    menu.classList.toggle("show");
    body.classList.toggle("show");
  }
};
burgerHandler();

/* **** Menu drop down **** */
const menuDropDown = () => {
  if (
    document.querySelectorAll(".main-menu__list__item.menu-item-has-children")
      .length > 0
  ) {
    const mainMenus = document.querySelectorAll(
      ".main-menu__list__item.menu-item-has-children"
    );
    const showEvents = ["mouseenter", "focus"];
    const hideEvents = ["mouseleave", "blur"];
    mainMenus.forEach((item) => {
      showEvents.forEach((event) => {
        item.addEventListener(event, () => {
          item.classList.add("show");
        });
      });
      hideEvents.forEach((event) => {
        item.addEventListener(event, () => {
          item.classList.remove("show");
        });
      });
    });
  }

  const showEvents = ["mouseenter", "focus"];
  const hideEvents = ["mouseleave", "blur"];

  if (
    document.querySelectorAll(".footer-menu__list__item.menu-item-has-children")
      .length > 0
  ) {
    function footerMenu(event) {
      const footerMenuList = document.querySelectorAll(
        ".footer-menu__list__item.menu-item-has-children"
      );
      footerMenuList.forEach((item) => {
        item.addEventListener(event, () => {
          item.classList.toggle("show");
        });
      });
    }
    showEvents.forEach((event) => footerMenu(event));
    hideEvents.forEach((event) => footerMenu(event));
  }
};
menuDropDown();

/* **** Toast **** */
import { Toast } from "bootstrap";

const toastContainer = document.querySelector(".toast");
const toastBody = toastContainer.querySelector(".toast-body");
const toastInit = new Toast(toastContainer, {
  animation: true,
  autohide: true,
  delay: 3000,
});
const setMessage = (event) => {
  const message = event.detail.apiResponse.message;
  toastBody.innerHTML = "";
  toastBody.innerHTML += "<span>" + message + "</span>";
};

/* **** Error **** */
document.addEventListener(
  "wpcf7invalid",
  function (event) {
    toastContainer.classList.remove("success");
    toastContainer.classList.add("error");
    setMessage(event);
    toastInit.show();
  },
  false
);

/* **** Success **** */
document.addEventListener(
  "wpcf7mailsent",
  function (event) {
    toastContainer.classList.remove("error");
    toastContainer.classList.add("success");
    setMessage(event);
    toastInit.show();
  },
  false
);
